import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { toast } from "react-toastify";
import { useSimplePaymentDataContext } from "../../context/SimplePaymentDataContext";
import { useFetch } from "../../hooks/useFetch";

import {
  QualityAssuranceFormSchema,
  QualityAssuranceType,
} from "../../schemas/QuallityAssuranceType";

import { QualityAssuranceSummary } from "../../pages/QualityAssuranceSummary";
import QualityAssurance from "../../pages/QualityAssurance";
import AddQualitySupplier from "../AddQualitySupplier";
import SpinnerIcon from "../Icons/SpinnerIcon";

import {
  add_quality_assurance,
  get_quality_assurance,
  update_quality_assurance,
} from "../../constants/apis";

// Types
interface QualityAssuranceResponse {
  status: string;
  quality_assurance_data: {
    quality_assurance_id: number;
    uid: number;
    cid: number;
    beneficiary_id: number;
    supplier_audit: boolean;
    quality_assurance: boolean;
    loading_inspection: boolean;
    audit_specification: string;
    quality_specifications_file: [{}];
    qa_consignment_value: number;
    no_of_containers: number;
    loading_consignment_value: number;
    goods_specifications: string;
    created_at: string;
  };
  beneficiary_data: any;
}

// export type QualityAssuranceType = z.infer<typeof QualityAssuranceFormSchema>;
type FormType = "add" | "update";

const steps = [
  { id: 1, name: "Quality Assurance" },
  { id: 2, name: "Summary" },
];

export default function QualityAssuranceForm() {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedCompany, setSelectedCompany] =
    useState<QualityAssuranceType>();
  const [formType, setFormType] = useState<FormType>();
  const [saveAsDraftLoading, setSaveAsDraftLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const addSupplierCompanyDetailsRef = useRef<HTMLDialogElement>(null);

  const { refetch } = useSimplePaymentDataContext();
  const { checkAvailability, jwtToken } = useAppContext();
  const navigate = useNavigate();
  const quality_assurance_id = searchParams.get("quality_assurance_id");

  const { data: qualityAssuranceData } = useFetch<{
    quality_assurance_data: QualityAssuranceType & {
      quality_assurance_id: number;
    };
  }>(get_quality_assurance, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
  });

  const methods = useForm<QualityAssuranceType>({
    resolver: zodResolver(QualityAssuranceFormSchema),

    defaultValues: { quality_specifications_file: [{}] },
    // defaultValues:
    //   currentStep == 1
    //     ? {
    //         quality_specifications_file: [{}],
    //         supplier_audit: false,
    //         quality_assurance: false,
    //         loading_inspection: false,
    //       }
    //     : {},
    // values: qualityAssuranceData?.quality_assurance_data,
    mode: "onChange",
  });
  const {
    formState: { errors, isSubmitting },
    reset,
    getValues,
    trigger,
    setFocus,
  } = methods;

  // Fetch Draft Data
  const { data: apiResponse, loading } = useFetch<QualityAssuranceResponse>(
    get_quality_assurance,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ quality_assurance_id }),
    },
    quality_assurance_id!
  );
  // console.log('checks',apiResponse)

  // Load API response data
  useEffect(() => {
    if (apiResponse?.status === "success") {
      const data = apiResponse?.quality_assurance_data;

      // Transform the data to match form structure
      const formData = {
        supplier_audit: data?.supplier_audit === 1 ? true : false,
        quality_assurance: data?.quality_assurance === 1 ? true : false,
        loading_inspection: data?.loading_inspection === 1 ? true : false,
        audit_specification: data?.audit_specification,
        qa_consignment_value: Number(data?.qa_consignment_value),
        no_of_containers: data?.no_of_containers,
        loading_consignment_value: data?.loading_consignment_value,
        goods_specifications: data?.goods_specifications,
        quality_specifications_file: data?.quality_specifications_file[0] || [],
        beneficiary_id: data?.beneficiary_id,
      };

      // reset(formData);
      console.log("formData", formData);
      // If there's beneficiary data, update selected company
      if (apiResponse.beneficiary_data) {
        setSelectedCompany({
          ...apiResponse.beneficiary_data,
          ...formData,
        });
      }
    }
  }, [apiResponse, reset]);
  const handleSaveAsDraft = async () => {
    setSaveAsDraftLoading(true);
    const formValues = getValues();

    const payload = {
      save_as_draft: 1,
      cid: 1,
      supplier_audit: formValues.supplier_audit ? true : false,
      quality_assurance: formValues.quality_assurance ? true : false,
      loading_inspection: formValues.loading_inspection ? true : false,
      audit_specification: formValues.audit_specification,
      qa_consignment_value: Number(formValues.qa_consignment_value),
      no_of_containers: Number(formValues.no_of_containers),
      loading_consignment_value: Number(formValues.loading_consignment_value),
      goods_specifications: formValues.goods_specifications,
      quality_specifications_file: [
        formValues.quality_specifications_file || [],
      ],
      beneficiary_id: apiResponse?.quality_assurance_data?.beneficiary_id,
      ...(quality_assurance_id && { quality_assurance_id }),
    };

    try {
      const response = await fetch(
        quality_assurance_id ? update_quality_assurance : add_quality_assurance,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const result = await response.json();
      console.log("result", result);
      if (result.status === "success") {
        toast.success("Successfully saved as draft");
        setSearchParams(
          new URLSearchParams({
            quality_assurance_id: result.quality_assurance_id,
          })
        );
        refetch();
      } else {
        toast.error(
          result.message || "Something went wrong! Please try again."
        );
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong! Please try again.");
    } finally {
      setSaveAsDraftLoading(false);
    }
  };

  const handleSubmitForm: SubmitHandler<QualityAssuranceType> = async (
    values
  ) => {
    console.log(values);

    if (!values.beneficiary_id) {
      toast.error("Please select a supplier company");
      return;
    }
    //  console.log('val',formValues.supplier_audit)
    // Transform boolean values to 0/1 for API

    // const payload = {
    //   cid: 1,
    //   supplier_audit: formValues.supplier_audit ? true : false,
    //   quality_assurance: formValues.quality_assurance ? true : false,
    //   loading_inspection: formValues.loading_inspection ? true : false,
    //   audit_specification: formValues.audit_specification,
    //   qa_consignment_value: Number(formValues.qa_consignment_value),
    //   no_of_containers: Number(formValues.no_of_containers),
    //   loading_consignment_value: Number(formValues.loading_consignment_value),
    //   goods_specifications: formValues.goods_specifications,
    //   quality_specifications_file: [
    //     formValues.quality_specifications_file || [],
    //   ],
    //   beneficiary_id: formValues.beneficiary_id,
    //   ...(quality_assurance_id && { quality_assurance_id }),
    // };

    // try {
    //   const saveResponse = await fetch(
    //     quality_assurance_id ? update_quality_assurance : add_quality_assurance,
    //     {
    //       method: "POST",
    //       headers: {
    //         Authorization: `Bearer ${jwtToken}`,
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify(payload),
    //     }
    //   );

    //   const saveResult = await saveResponse.json();

    //   if (saveResult.status !== "success") {
    //     throw new Error(saveResult.message || "Failed to save form data");
    //   }

    //   const emailResponse = await fetch(service_submit_mail, {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Bearer ${jwtToken}`,
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       type: "quality assurance",
    //       quality_assurance_id: saveResult.quality_assurance_id,
    //     }),
    //   });

    //   const emailResult = await emailResponse.json();

    //   if (emailResult.status === "success") {
    //     passwordAndOtpPopupRef.current?.showModal();
    //   } else {
    //     throw new Error(emailResult.message || "Failed to send email");
    //   }
    // } catch (error) {
    //   toast.error(
    //     error instanceof Error ? error.message : "Unexpected error occurred."
    //   );
    // }
  };

  const handleNextStep = async () => {
    const valid = await trigger();
    console.log(getValues());

    if (valid) {
      setCurrentStep((prev) => prev + 1);

      window.scrollTo({ top: 0 });
    } else {
      const firstErrorField = Object.keys(errors)[0];
      if (firstErrorField) {
        setFocus(firstErrorField as keyof QualityAssuranceType);
      }
    }
  };

  const handlePrevStep = () => {
    setCurrentStep((prev) => prev - 1);
    window.scrollTo({ top: 0 });
  };

  const handleOpenModal = (type: FormType) => {
    setFormType(type);
    addSupplierCompanyDetailsRef.current?.showModal();
  };
  console.log("qaformerrors", errors);
  console.log(
    "qaresponsecheck",
    apiResponse?.quality_assurance_data?.beneficiary_id
  );
  return (
    <div className="flex">
      {/* Sidebar Navigation */}
      <nav className="w-[250px] p-1 flex flex-col gap-[1px] border-r border-[#EAECF0]">
        {steps.map((step) => (
          <div key={step.id}>
            <div
              className={`w-full py-2 ${
                step.id === currentStep
                  ? "text-custom-blue-medium"
                  : "text-[#475467]"
              }`}
            >
              <p className="flex items-center gap-1">
                <span
                  className={`w-8 h-8 text-xs font-semibold rounded-full inline-flex items-center justify-center border-2 ${
                    step.id === currentStep
                      ? "text-custom-blue-medium border-custom-blue-medium"
                      : "border-[#D0D5DD]"
                  }`}
                >
                  0{step.id}
                </span>
                {step.name}
              </p>
            </div>
            {step.id !== steps.length && (
              <div className="w-[1px] h-12 bg-[#D0D5DD] ml-4" />
            )}
          </div>
        ))}
      </nav>

      {/* Main Content */}
      <div className="relative w-full">
        {!checkAvailability && (
          <div className="absolute inset-0 z-10 backdrop-blur-sm flex justify-center items-center">
            <p className="text-red-300 font-medium">
              Please fill in the origin and destination details to proceed to
              the next step.
            </p>
          </div>
        )}

        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(handleSubmitForm)}
            className="px-8"
          >
            {loading && (
              <div className="backdrop-blur-sm rounded-lg absolute inset-0 z-30" />
            )}

            {currentStep === 1 && (
              <QualityAssurance
                selectedCompany={selectedCompany}
                setSelectedCompany={setSelectedCompany}
                openModal={handleOpenModal}
              />
            )}
            {currentStep === 2 && <QualityAssuranceSummary />}

            {/* Form Actions */}
            <div className="mt-4 py-6 flex justify-between border-t border-[#EAECF0]">
              {currentStep === 1 ? (
                <button
                  type="button"
                  className="w-[87px] h-[44px] border border-[#D0D5DD] text-[#344054] font-medium rounded-lg"
                  onClick={() => navigate("/dashboard")}
                >
                  Cancel
                </button>
              ) : (
                <button
                  type="button"
                  className="w-[87px] h-[44px] border border-[#D0D5DD] text-[#344054] font-medium rounded-lg"
                  onClick={handlePrevStep}
                >
                  Back
                </button>
              )}

              <div className="flex gap-3">
                <button
                  type="button"
                  onClick={handleSaveAsDraft}
                  disabled={saveAsDraftLoading || isSubmitting}
                  className="w-[166px] h-[44px] border border-custom-blue-medium text-custom-blue-medium font-medium rounded-lg"
                >
                  {saveAsDraftLoading ? (
                    <SpinnerIcon className="size-4 text-gray-200 animate-spin fill-blue-600 inline-flex justify-center" />
                  ) : (
                    "Save as Draft"
                  )}
                </button>

                {currentStep === 1 && (
                  <button
                    type="button"
                    onClick={handleNextStep}
                    className="w-[146px] h-[44px] rounded-md bg-custom-blue-medium text-white font-medium"
                  >
                    Next
                  </button>
                )}

                {currentStep === 2 && (
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    // onClick={handleNextStep}
                    className="w-[146px] h-[44px] rounded-md bg-custom-blue-medium text-white font-medium"
                  >
                    {isSubmitting ? (
                      <SpinnerIcon className="size-4 text-gray-200 animate-spin fill-blue-600 inline-flex justify-center" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                )}
              </div>
            </div>
          </form>
        </FormProvider>
      </div>

      {/* Modals */}
      <dialog ref={addSupplierCompanyDetailsRef} className="rounded-md">
        <AddQualitySupplier
          formType={formType}
          selectedCompany={selectedCompany}
          onClose={() => addSupplierCompanyDetailsRef.current?.close()}
        />
      </dialog>
    </div>
  );
}