interface DropdownDataProps {
  [key: string]: {
    transportationBy: {
      [key: string]: {
        [key: string]: string[];
      };
    };
  };
}
export const emailRegexExpression = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const phoneNumberRegex = /[^0-9]/g;
export const numberRegex = /[^0-9]/g;

export const dropdownData: DropdownDataProps = {
  sea: {
    transportationBy: {},
  },
  land: {
    transportationBy: {
      road: {
        "Full truck load FTL": [
          "TIR - 22m3/18mt",
          "Megas - 22m3/20mt",
          "Jumbo - 22m3/22mt",
          "Dry van - 30m3",
          "Refrigerator - 20m3",
          // Add more options here...
        ],
        "Less truck load LTL": [
          "20' Standard",
          "40' Standard",
          "40' High Cube",
          "20' Reefer",
          "40' Reefer",
          // Add more options here...
        ],
      },
      rail: {
        "Full container load FCL": [
          "20' Standard",
          "40' Standard",
          "40' High Cube",
          "20' Tank",
          "40' Platform",
          // Add more options here...
        ],
      },
    },
  },
  air: {
    transportationBy: {},
  },
  auto: {
    transportationBy: {},
  },
};

export const TWO_PERCENT = 2 / 100;

// local storage key
export const SP_BENEFICIARY_DATA = "sp-beneficiary-data";
export const SP_DETAILS_DATA = "sp-details-data";

// logistics
export const SHIPPING_TYPE = ["Sea", "Land", "Air", "Auto"];
export const TRANSPORTATION_BY = [
  {
    value: "FCL",
    label: "Full container load FCL",
  },
  {
    value: "LCL",
    label: "Less container load LCL",
  },
  {
    value: "Bulk",
    label: "Bulk",
  },
];

export const CONTAINER_TYPE = [
  { value: "ST20", label: "20' Standard" },
  { value: "ST40", label: "40' Standard" },
  { value: "HQ20", label: "20' High Cube" },
  { value: "HQ40", label: "40' High Cube" },
  { value: "RF20", label: "20' Refrigerated" },
  { value: "RF40", label: "40' Refrigerated" },
  { value: "OT20", label: "20' Open Top" },
  { value: "OT40", label: "40' Open Top" },
  { value: "FR20", label: "20' Flat Rack" },
  { value: "FR40", label: "40' Flat Rack" },
  { value: "TK20", label: "20' Tank Container" },
  { value: "TK40", label: "40' Tank Container" },
  { value: "HQ45", label: "45' High Cube" },
  { value: "DH20", label: "20' Double Door" },
  { value: "DH40", label: "40' Double Door" },
];

export const businessDetails = {
  ANNUAL_TURNOVER: "annualTurnover",
  BUSINESS_TYPE: "businessType",
  COUNTRY_NAME: "countryName",
  COUNTRY_OF_OPERATION: "countryOfOperation",
  DOCUMENT_TYPE: "documentType",
  INTENDED_USE_OF_ACCOUNT: "intendedUseOfAccount",
  INDUSTRY_SECTOR: "industrySector",
  LISTED_EXCHANGE: "listedExchange",
  POSITION: "position",
  REGULATED_TRUST_TYPE: "regulatedTrustType",
  RESTRICTED_COUNTRIES: "restrictedCountries",
  STATE: "state",
  STREET_TYPE: "streetType",
  TOTAL_EMPLOYEES: "totalEmployees",
  UNREGULATED_TRUST_TYPE: "unregulatedTrustType",
  RFI_TEMPLATES: "rfiTemplates",
  ESTIMATED_MONTHLY_FUNDING: "estimatedMonthlyFunding",
  OCCUPATION: "occupation",
  MONTHLY_TRANSACTION_VOLUME: "monthlyTransactionVolume",
  AVERAGE_TRANSACTION_VALUE: "averageTransactionValue",
  MONTHLY_TRANSACTIONS: "monthlyTransactions",
  TRUST_BENEFICIARY_CLASS: "trustBeneficiaryClass",
  CAPITAL_CONTRIBUTION: "capitalContribution",
  VOTING_RIGHTS: "votingRights",
};

export const businessRegion = "US";

export const PHONE_NO_COUNTRY_CODE = [
  { countryCode: "US", label: "+1 (United States)" },
  { countryCode: "CA", label: "+1 (Canada)" },
  { countryCode: "IN", label: "+91 (India)" },
  { countryCode: "GB", label: "+44 (United Kingdom)" },
  { countryCode: "AU", label: "+61 (Australia)" },
  { countryCode: "DE", label: "+49 (Germany)" },
  { countryCode: "FR", label: "+33 (France)" },
  { countryCode: "IT", label: "+39 (Italy)" },
  { countryCode: "ES", label: "+34 (Spain)" },
  { countryCode: "RU", label: "+7 (Russia)" },
  { countryCode: "BR", label: "+55 (Brazil)" },
  { countryCode: "MX", label: "+52 (Mexico)" },
  { countryCode: "JP", label: "+81 (Japan)" },
  { countryCode: "CN", label: "+86 (China)" },
  { countryCode: "NG", label: "+234 (Nigeria)" },
  { countryCode: "KR", label: "+82 (South Korea)" },
  { countryCode: "AE", label: "+971 (United Arab Emirates)" },
  { countryCode: "PH", label: "+63 (Philippines)" },
  { countryCode: "VN", label: "+84 (Vietnam)" },
  { countryCode: "SA", label: "+966 (Saudi Arabia)" },
  { countryCode: "ID", label: "+62 (Indonesia)" },
  { countryCode: "PK", label: "+92 (Pakistan)" },
  { countryCode: "EG", label: "+20 (Egypt)" },
  { countryCode: "TH", label: "+66 (Thailand)" },
  { countryCode: "SE", label: "+46 (Sweden)" },
  { countryCode: "PL", label: "+48 (Poland)" },
  { countryCode: "NO", label: "+47 (Norway)" },
  { countryCode: "AT", label: "+43 (Austria)" },
  { countryCode: "CH", label: "+41 (Switzerland)" },
  { countryCode: "BE", label: "+32 (Belgium)" },
  { countryCode: "NL", label: "+31 (Netherlands)" },
  { countryCode: "DK", label: "+45 (Denmark)" },
  { countryCode: "FI", label: "+358 (Finland)" },
  { countryCode: "GR", label: "+30 (Greece)" },
  { countryCode: "IE", label: "+353 (Ireland)" },
  { countryCode: "RO", label: "+40 (Romania)" },
  { countryCode: "HR", label: "+385 (Croatia)" },
  { countryCode: "UA", label: "+380 (Ukraine)" },
  { countryCode: "CZ", label: "+420 (Czech Republic)" },
  { countryCode: "PT", label: "+351 (Portugal)" },
  { countryCode: "CL", label: "+56 (Chile)" },
  { countryCode: "AR", label: "+54 (Argentina)" },
  { countryCode: "PE", label: "+51 (Peru)" },
  { countryCode: "CO", label: "+57 (Colombia)" },
  { countryCode: "VE", label: "+58 (Venezuela)" },
  { countryCode: "BO", label: "+591 (Bolivia)" },
  { countryCode: "EC", label: "+593 (Ecuador)" },
  { countryCode: "PY", label: "+595 (Paraguay)" },
  { countryCode: "HN", label: "+504 (Honduras)" },
  { countryCode: "GT", label: "+502 (Guatemala)" },
  { countryCode: "CR", label: "+506 (Costa Rica)" },
  { countryCode: "SV", label: "+503 (El Salvador)" },
  { countryCode: "UY", label: "+598 (Uruguay)" },
  { countryCode: "BS", label: "+1-242 (Bahamas)" },
  { countryCode: "BJ", label: "+229 (Benin)" },
  { countryCode: "BT", label: "+975 (Bhutan)" },
  { countryCode: "BW", label: "+267 (Botswana)" },
  { countryCode: "BI", label: "+257 (Burundi)" },
  { countryCode: "KH", label: "+855 (Cambodia)" },
  { countryCode: "CM", label: "+237 (Cameroon)" },
  { countryCode: "CV", label: "+238 (Cape Verde)" },
  { countryCode: "GA", label: "+241 (Gabon)" },
  { countryCode: "GM", label: "+220 (Gambia)" },
  { countryCode: "GH", label: "+233 (Ghana)" },
  { countryCode: "GN", label: "+224 (Guinea)" },
  { countryCode: "GW", label: "+245 (Guinea-Bissau)" },
  { countryCode: "KE", label: "+254 (Kenya)" },
  { countryCode: "LS", label: "+266 (Lesotho)" },
  { countryCode: "LR", label: "+231 (Liberia)" },
  { countryCode: "MW", label: "+265 (Malawi)" },
  { countryCode: "ML", label: "+223 (Mali)" },
  { countryCode: "MO", label: "+853 (Macau)" },
  { countryCode: "MN", label: "+976 (Mongolia)" },
  { countryCode: "MZ", label: "+258 (Mozambique)" },
  { countryCode: "NA", label: "+264 (Namibia)" },
  { countryCode: "NP", label: "+977 (Nepal)" },
  { countryCode: "OM", label: "+968 (Oman)" },
  { countryCode: "QA", label: "+974 (Qatar)" },
  { countryCode: "RS", label: "+381 (Serbia)" },
  { countryCode: "SG", label: "+65 (Singapore)" },
  { countryCode: "SI", label: "+386 (Slovenia)" },
  { countryCode: "SK", label: "+421 (Slovakia)" },
  { countryCode: "ZA", label: "+27 (South Africa)" },
  { countryCode: "ZW", label: "+263 (Zimbabwe)" },
];

export const exemptionCode = [
  {
    code: "01",
    description: "Trusted Beneficiary",
  },
  {
    code: "03",
    description: "Recurring Transactions",
  },
  {
    code: "04",
    description: "Payment to Self",
  },
];

export const sourceOfFunds = [
  "Salary",
  "Personal Savings",
  "Personal Wealth",
  "Retirement Funds",
  "Business Owner/Shareholder",
  "Loan Facility",
  "Personal Account",
  "Corporate Account",
];

export const purposeCode = [
  {
    code: "IR001",
    description: "Transfer to own account",
  },
  {
    code: "IR002",
    description: "Family Maintenance",
  },
  {
    code: "IR003",
    description: "Education-related student expenses",
  },
  {
    code: "IR004",
    description: "Medical Treatment",
  },
  {
    code: "IR005",
    description: "Hotel Accommodation",
  },
  {
    code: "IR006",
    description: "Travel",
  },
  {
    code: "IR007",
    description: "Utility Bills",
  },
  {
    code: "IR008",
    description: "Repayment of Loans",
  },
  {
    code: "IR009",
    description: "Tax Payment",
  },
  {
    code: "IR010",
    description: "Purchase of Residential Property",
  },
  {
    code: "IR011",
    description: "Payment of Property Rental",
  },
  {
    code: "IR012",
    description: "Insurance Premium",
  },
  {
    code: "IR013",
    description: "Product indemnity insurance",
  },
  {
    code: "IR014",
    description: "Insurance Claims Payment",
  },
  {
    code: "IR015",
    description: "Mutual Fund Investment",
  },
  {
    code: "IR016",
    description: "Investment in Shares",
  },
  {
    code: "IR017",
    description: "Donations",
  },
  {
    code: "IR01801",
    description: "Information Service Charges",
  },
  {
    code: "IR01802",
    description: "Advertising & Public relations-related expenses",
  },
  {
    code: "IR01803",
    description:
      "Royalty fees, trademark fees, patent fees, and copyright fees",
  },
  {
    code: "IR01804",
    description:
      "Fees for brokers, front end fee, commitment fee, guarantee fee and custodian fee",
  },
  {
    code: "IR01805",
    description:
      "Fees for advisors, technical assistance, and academic knowledge, including remuneration for specialists",
  },
  {
    code: "IR01806",
    description: "Representative office expenses",
  },
  {
    code: "IR01807",
    description: "Construction costs/expenses",
  },
  {
    code: "IR01808",
    description: "Transportation fees for goods",
  },
  {
    code: "IR01809",
    description: "For payment of exported goods",
  },
  {
    code: "IR01810",
    description: "Delivery fees for goods",
  },
  {
    code: "IR01811",
    description: "General Goods Trades - Offline trade",
  },
];

export const COMPANY_REGION = [
  {
    value: "US",
    label: "USA",
  },
  {
    value: "EU",
    label: "Europe",
  },
];
