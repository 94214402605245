import { Outlet } from "react-router-dom";

import UserDropdown from "../components/UserDropdown";
import SideNavbar from "../components/VTabBar/SideNavbar";

const VTabBarlayout = () => {
  return (
    <main className="flex">
      <SideNavbar />

      <section className="flex-1 ml-[264px] min-h-screen pb-10 bg-[#EAECF0]">
        <nav className="max-w-[1128px] mx-auto flex justify-end py-4 items-center">
          <UserDropdown />
        </nav>
        <section className="max-w-[1128px] mx-auto">
          <Outlet />
        </section>
      </section>
    </main>
  );
};

export default VTabBarlayout;
