import { Link } from "react-router-dom";

import taramLogo from "../../assets/icons/taramTextLogo.png";
import {
  SimplePaymentIcon,
  SmartEscrowIcon,
  DashboardIcon,
  // ChartBreakoutCircle,
  // SettingsIcon,
  HelpCircleIcon,
  IFIcon,
  LogisticsIcon,
  QualityAssuranceIcon,
  // InsuranceIcon,
  LogOutIcon,
} from "../../components/Icons";

import LogOutBtn from "../../components/LogOutBtn";
import VTabBarLink from "./VTabBarLink";

import {
  invoiceContent,
  logisiticsContent,
  paymentContent,
  qualityContent,
} from "../../constants/tooltipContents";

const routes = [
  {
    id: 1,
    route: "/dashboard",
    name: "Dashboard",
    content: paymentContent,
    icon: <DashboardIcon className="size-6" />,
  },
  {
    id: 2,
    route: "/simple-payment",
    name: "Payment",
    content: paymentContent,
    icon: <SimplePaymentIcon className="size-7" />,
  },
  {
    id: 3,
    route: "/invoice-factoring",
    name: "Invoice Factoring",
    content: invoiceContent,
    icon: <IFIcon className="size-7" />,
  },
  // {
  //   id: 4,
  //   route: "/smart-escrow",
  //   name: "Smart Escrow",
  //   icon: <SmartEscrowIcon className="size-7" />,
  //   disabled: true,
  // },

  {
    id: 5,
    route: "/logistics",
    name: "Logistics",
    content: logisiticsContent,
    icon: <LogisticsIcon className="size-7" />,
  },
  {
    id: 6,
    route: "/quality-assurance",
    name: "Quality Assurance",
    content: qualityContent,
    icon: <QualityAssuranceIcon className="size-7" />,
  },

  // {
  //   id: 7,
  //   route: "/insurance",
  //   name: "Insurance",
  //   icon: <InsuranceIcon className="size-7" />,
  // },
  {
    id: 4,
    route: "/smart-escrow",
    name: "Smart Escrow",
    icon: <SmartEscrowIcon className="size-7" />,
    disabled: true,
    content: "Coming soon",
  },
  // {
  //   id: 8,
  //   route: "/transaction-summary",
  //   name: "Summary",
  //   icon: <ChartBreakoutCircle className="size-7" />,
  // },
];

type RoutesType = typeof routes;
export type LinkProps = RoutesType[number];

export default function SideNavbar() {
  return (
    <aside className="w-[264px] h-full fixed top-0 z-50 bg-custom-dark-blue-new px-4 py-8 flex flex-col justify-between">
      <div>
        <div className="mb-10 pl-4">
          <Link to={"/"}>
            <img src={taramLogo} alt="logo" />
          </Link>
        </div>

        {routes.map((el) => (
          <VTabBarLink key={el.id} el={el} />
        ))}
      </div>

      <div className="text-[#C6C8D0] space-y-4">
        <Link
          to="help-and-support"
          className="pl-4 flex items-center gap-3 hover:underline"
        >
          <HelpCircleIcon className="size-6" /> <span>Help & Support</span>
        </Link>

        <LogOutBtn className="w-full pl-4 rounded-lg hover:underline flex items-center gap-3">
          <LogOutIcon className="size-6" /> <span>Log Out</span>
        </LogOutBtn>
      </div>
    </aside>
  );
}
