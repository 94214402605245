import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";

export default function ActionBanner() {
  const navigate = useNavigate();
  const { user } = useAppContext();

  return (
    user?.status === null && (
      <div className="card flex justify-between items-center mb-8">
        <div>
          <span className="py-2 rounded-md text-red-400 font-medium">
            ACTION REQUIRED!
          </span>
          <span className="mx-10 text-gray-700">
            Complete onboarding to begin using your account.
          </span>
        </div>
        <button
          onClick={() => navigate("/registration")}
          className="bg-custom-blue-new px-4 py-1 rounded-md text-sm text-white font-bold"
        >
          Update
        </button>
      </div>
    )
  );
}
