import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { useAppContext } from "../context/AppContext";

import UserDropdown from "./UserDropdown";
import TaramLogo from "./Icons/TaramLogo";
import Modal from "./Modal";
import { LoginForm, SignupForm } from "./landingPageComponents/LoginSignUpForm";

export const NavBar = () => {
  const { jwtToken, isModalOpen, toggleModal, modalContent, setModalContent } =
    useAppContext();

  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (!dialogRef.current) {
      return;
    }

    if (isModalOpen) {
      dialogRef.current.showModal();

      document.body.style.overflow = "hidden";
    } else {
      dialogRef.current.close();

      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);

  return (
    <nav>
      <div className="px-4 md:px-20 py-5 container mx-auto flex justify-between items-center">
        <Link to={"/"}>
          <TaramLogo className="w-[150px] h-[43px]" />
        </Link>
        {jwtToken ? (
          <UserDropdown />
        ) : (
          <div className="flex items-center gap-5">
            <button
              className="text-custom-blue-new font-medium hover:underline"
              onClick={() => {
                setModalContent(<LoginForm />);
                toggleModal();
              }}
            >
              Login
            </button>
            <button
              className="bg-custom-blue-new text-white px-2 py-2 rounded-lg"
              onClick={() => {
                setModalContent(<SignupForm />);
                toggleModal();
              }}
            >
              Get started
            </button>
          </div>
        )}
      </div>

      <Modal
        toggleDialog={toggleModal}
        ref={dialogRef}
        className="relative py-5 px-10 rounded-lg backdrop:bg-black/40"
      >
        {modalContent}
      </Modal>
    </nav>
  );
};
