import { useOutletContext } from "react-router-dom";

import { GeoNames } from "../components/SearchInput";

import InputWithLabel from "../components/InputWithLabel";
import { SubmitHandler, useForm } from "react-hook-form";
import Switch from "../components/Switch";
import SimpleBooking from "../components/Logistics/SimpleBooking";
import Tooltip from "../components/Tooltip";
import {
  logisiticsContent,
  shipping_category,
  transportation_by,
} from "../constants/tooltipContents";

interface LCargoDetailsFieldType {
  lCargoCategory?: string | number;
  lCargoValueOfGoods?: string | number;
  lCargoTransportationBy?: string | number;
  lCargoContainerType?: string | number;
  lCargoContainerQuantity?: string | number;
  lCargoFreightBasis?: string | number;
  lCargoFrom?: string | number;
  lCargoTo?: string | number;
  lCargoLoadingDate?: string | number;
  lCargoWeight?: string | number;
}

export type FormValuesType = {
  from: Omit<GeoNames, "adminCode1" | "geonameId">;
  to: Omit<GeoNames, "adminCode1" | "geonameId">;
  date: string;
  shipppingType: {
    container_size: string;
    freight_type: string;
    transportation_by: string;
  };
};

type ContextType = { isChecked: string };

const LCargoDetails = () => {
  const { isChecked } = useOutletContext<ContextType>();

  return (
    <section className="relative pl-10 pr-2">
      <div>
        {/* <div className="flex justify-end">
            <Switch
              checked={isChecked}
              onToggle={handleToggle}
              checkPayment={false}
              paymentOne={"Simple Booking"}
              paymentTwo={"Custom Booking"}
            />
          </div> */}

        {isChecked == "simpleBooking" ? (
          <SimpleBooking />
        ) : (
          <LCDCustomBooking />
        )}
      </div>
    </section>
  );
};

export default LCargoDetails;

function LCDCustomBooking() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LCargoDetailsFieldType>();

  const onSubmitHandler: SubmitHandler<LCargoDetailsFieldType> = (values) => {
    console.log(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className="space-y-2">
      <div className="flex items-center gap-4">
        <InputWithLabel
          element={<Tooltip content={shipping_category} />}
          type="text"
          label={"Category"}
          id={"category"}
          {...register("lCargoCategory", {
            required: "Category is required",
          })}
          error={errors.lCargoCategory?.message}
        />
        <InputWithLabel
          type="text"
          element={<Tooltip content={transportation_by} />}
          label={"Transportation By"}
          id={"transportationBy"}
          {...register("lCargoTransportationBy", {
            required: "Transportation by is required",
          })}
          error={errors.lCargoTransportationBy?.message}
        />

        <div className="flex w-[250px] space-x-1">
          <input type="radio" name="byUnits" className="w-4" />
          <label htmlFor="byUnits">By Units</label>
        </div>
      </div>

      {/* <div className="flex items-center gap-9">
        <InputWithLabel
          type="text"
          label={"VALUE OF GOODS($)"}
          id={"LValueOfGoods"}
          {...register("lCargoValueOfGoods", {
            required: "Value Of goods is required",
          })}
          error={errors.lCargoValueOfGoods?.message}
        />
        <InputWithLabel
          type="text"
          label={"CONTAINER TYPE"}
          id={"containerType"}
          {...register("lCargoContainerType", {
            required: "Container type is required",
          })}
          error={errors.lCargoContainerType?.message}
        />
      </div> */}

      <div className="flex items-center gap-2">
        {/* <InputWithLabel
          type="text"
          label={"CONTAINER QUANTITY"}
          id={"containerQuantity"}
          {...register("lCargoContainerQuantity", {
            required: "Container quantity is required",
          })}
          error={errors.lCargoContainerQuantity?.message}
        /> */}
        <div>
          <label htmlFor="lot" className="text-[#344054] text-sm font-medium">
            LOT
          </label>
          <input type="text" name="lot" className="input-new !w-[150px]" />
        </div>
        <div>
          <label
            htmlFor="container_quantity"
            className="text-[#344054] text-sm font-medium"
          >
            Container Quantity
          </label>
          <div className="input-new h-fit rounded-md flex items-center selection:text-green-700">
            <input className="w-full bg-transparent" type="number" />
            <input className="w-full  bg-transparent" type="number" />
            <input className="w-full  bg-transparent" type="number" />
            <input className="w-full  bg-transparent" type="number" />
          </div>
        </div>
        <div>
          <label
            htmlFor="quantity"
            className="text-[#344054] text-sm font-medium"
          >
            Quantity
          </label>
          <input className="!w-[100px] input-new" type="text" />
        </div>
        <div>
          <label
            htmlFor="gross_weight"
            className="text-[#344054] text-sm font-medium"
          >
            *Gross Weight
          </label>
          <input type="text" className="!w-[200px] input-new" />
        </div>
      </div>

      <div className="flex items-center gap-9">
        <InputWithLabel
          type="text"
          label={"From"}
          id={"logisticsForm"}
          {...register("lCargoFrom", { required: "Field is required" })}
          error={errors.lCargoFrom?.message}
        />
        <InputWithLabel
          label={"Zip Code"}
          id={"zipCode"}
          // {...register("ZipCodeFrom", { required: "Field is required" })}
          error={errors.lCargoTo?.message}
        />
        <InputWithLabel
          label={"FREIGHT BASIS"}
          id={"freightBasis"}
          {...register("lCargoFreightBasis", {
            required: "Freight basis is required",
          })}
          error={errors.lCargoFreightBasis?.message}
        />
      </div>

      <div className="flex items-center gap-9">
        <InputWithLabel
          label={"To"}
          id={"logisticsTo"}
          {...register("lCargoTo", { required: "Field is required" })}
          error={errors.lCargoTo?.message}
        />
        <InputWithLabel
          label={"Zip Code"}
          id={"zipCode"}
          // {...register("ZipCodeTo", { required: "Field is required" })}
          error={errors.lCargoTo?.message}
        />
        <InputWithLabel
          label={"Ready to Load"}
          id={"logisticsWeight"}
          type="date"
          // {...register("loadingDate", { required: "Field is required" })}
          error={errors.lCargoWeight?.message}
        />
      </div>

      <div className="flex items-center gap-9">
        <InputWithLabel
          label={"Associated Services"}
          id={"logisticsTo"}
          {...register("lCargoTo", { required: "Field is required" })}
          error={errors.lCargoTo?.message}
        />
        <InputWithLabel
          label={"Input"}
          id={"input"}
          // {...register("input", { required: "Field is required" })}
          error={errors.lCargoTo?.message}
        />

        {/* <InputWithLabel
          label={"WEIGHT"}
          id={"logisticsWeight"}
          {...register("lCargoWeight", { required: "Field is required" })}
          error={errors.lCargoWeight?.message}
        /> */}
      </div>
      <div className="text-center">
        <button
          type="submit"
          className="bg-custom-blue text-white w-36 h-12 rounded mt-10"
        >
          Next
        </button>
      </div>
    </form>
  );
}
