import { Link, useNavigate } from "react-router-dom";

import Dropdown from "./DropDown";
import FlagDropdown from "./FlagDropdown";
import TaramLogo from "./Icons/TaramLogo";
import { useAppContext } from "../context/AppContext";
import { SignupForm } from "./landingPageComponents/LoginSignUpForm";

const options = [
  { value: "United States", label: "United States", flag: "/flags/us.svg" },
  { value: "India", label: "India", flag: "/flags/fr.svg" },
];
const Footer = () => {
  const { toggleModal, setModalContent, jwtToken } = useAppContext();
  const navigate = useNavigate();

  return (
    <footer>
      <div className="text-center px-4 py-10 md:p-20">
        <h1 className="font-semibold text-4xl">
          Put us to work as your B2B global cross-border trade platform
        </h1>
        <h2 className="max-w-[700px] mx-auto text-[14.88px] my-4 leading-6">
          Taram works to optimize your cross-border operations as an exporter or
          an importer. Let Taram get your global trade done faster, cheaper and
          more secure, right from the very first click.
        </h2>
        <div className="flex justify-center items-center space-x-2">
          <button
            onClick={() => {
              jwtToken
                ? navigate("/dashboard")
                : (setModalContent(<SignupForm />), toggleModal());
            }}
            className="border-[1px] border-black text-black w-[200px] h-[50px] rounded-lg"
          >
            Open an Account
          </button>
          <button
            onClick={() => {
              jwtToken
                ? navigate("/dashboard")
                : (setModalContent(<SignupForm />), toggleModal());
            }}
            className="bg-custom-blue text-white w-[200px] h-[50px] rounded-lg"
          >
            Book a Demo
          </button>
        </div>
      </div>
      <div className="container mx-auto px-4 md:px-20 text-[#2c2c2c]  text-[14px]  pt-10">
        {/* <FlagDropdown label={""} options={options}/> */}
        <div className="flex flex-wrap justify-between py-5 border-t-2 text-[#6C747F]">
          <div className="pb-6  flex flex-col justify-between">
            <TaramLogo className="w-[150px]" />
            <p>
              © TARAM 2024. All rights reserved.<span>Sitemap</span>{" "}
            </p>
          </div>
          <div className=" space-y-2">
            <p>Terms & policies</p>
            <p>Cookie Preference Center</p>
          </div>
          <div className="space-y-2">
            <p>manju@taram.io</p>
            <p>Europe: +420 775225229</p>
            <p>USA: +1 316 768 0502</p>
          </div>
          <div className="flex items-center space-x-4">
            <svg
              width={15}
              height={16}
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.012 15.6299H0.828C0.608401 15.6299 0.397796 15.5426 0.242516 15.3874C0.0872355 15.2321 0 15.0215 0 14.8019L0 1.45788C0 0.999883 0.37 0.629883 0.828 0.629883H14.172C14.629 0.629883 15 0.999883 15 1.45788V14.8019C15 15.2589 14.63 15.6299 14.172 15.6299H10.35V9.82088H12.3L12.591 7.55688H10.35V6.11188C10.35 5.45688 10.532 5.00988 11.472 5.00988H12.67V2.98388C12.463 2.95588 11.751 2.89388 10.924 2.89388C9.195 2.89388 8.012 3.94888 8.012 5.88688V7.55688H6.057V9.81988H8.012V15.6299Z"
                fill="black"
              />
            </svg>
            <svg
              width={15}
              height={16}
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_3125_5260"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={15}
                height={16}
              >
                <path
                  d="M14.3411 0.879883H0.160156V15.3799H14.3411V0.879883Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_3125_5260)">
                <path
                  d="M8.59974 7.01649L13.8789 0.879883H12.6279L8.04401 6.20821L4.38286 0.879883H0.160156L5.69654 8.93726L0.160156 15.3725H1.41122L6.25194 9.74554L10.1184 15.3725H14.3411L8.59974 7.01649ZM6.88623 9.00825L6.32528 8.20591L1.862 1.82166H3.78356L7.38548 6.97396L7.94644 7.77629L12.6285 14.4735H10.707L6.88623 9.00825Z"
                  fill="black"
                />
              </g>
            </svg>
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.782 13.4119H10.558V9.93088C10.558 9.10088 10.543 8.03288 9.402 8.03288C8.245 8.03288 8.067 8.93688 8.067 9.87088V13.4119H5.844V6.25288H7.978V7.23188H8.008C8.305 6.66888 9.031 6.07588 10.113 6.07588C12.366 6.07588 12.782 7.55788 12.782 9.48588V13.4119ZM3.337 5.27488C3.16759 5.27488 2.99985 5.24152 2.84334 5.17669C2.68683 5.11186 2.54462 5.01684 2.42483 4.89705C2.30504 4.77726 2.21002 4.63505 2.1452 4.47854C2.08037 4.32203 2.047 4.15429 2.047 3.98488C2.047 3.81548 2.08037 3.64773 2.1452 3.49122C2.21002 3.33471 2.30504 3.1925 2.42483 3.07272C2.54462 2.95293 2.68683 2.85791 2.84334 2.79308C2.99985 2.72825 3.16759 2.69488 3.337 2.69488C3.67913 2.69488 4.00725 2.83079 4.24917 3.07272C4.49109 3.31464 4.627 3.64275 4.627 3.98488C4.627 4.32701 4.49109 4.65513 4.24917 4.89705C4.00725 5.13897 3.67913 5.27488 3.337 5.27488ZM2.224 13.4119H4.449V6.25288H2.224V13.4119ZM13.89 0.629883H1.107C0.497 0.629883 0 1.11388 0 1.70988V14.5479C0 15.1449 0.496 15.6299 1.107 15.6299H13.89C14.502 15.6299 15 15.1449 15 14.5479V1.70988C15 1.11388 14.502 0.629883 13.89 0.629883Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </div>

      {/* <div className=" text-[#2c2c2c] container mx-auto px-20 py-24 flex justify-between"> */}
      {/* <div className="w-[500px]">
          <div className="flex items-center">
            <img src={taramLogo} alt="logo" className="w-14" />
            <p className="text-custom-blue text-4xl font-medium">TARAM </p>
          </div>

          <div className="mt-5">
            <p>
              Taram is a full-service digital marketing agency committed to
              helping businesses thrive in the digital age. our expert team
              delivers innovative solutions tailored to your needs,
            </p>
          </div>
        </div> */}

      {/* <div className=" flex gap-20"> */}
      {/* <div className="flex flex-col gap-2 space-y-2">
            <h3 className="text-lg font-semibold">Quick Links</h3>
            <Link className="font-medium" to={""}>
              Home
            </Link>
            <Link className="font-medium" to={""}>
              About
            </Link>
            <Link className="font-medium" to={""}>
              Services
            </Link>
            <Link className="font-medium" to={""}>
              Contact
            </Link>
          </div> */}

      {/* <div className="flex flex-col items-center gap-2 space-y-2">
            <h3 className="text-lg font-semibold">Follow Us</h3>
            <Link className="" to={""}>
              <img src={instagram} />
            </Link>
            <Link className="" to={""}>
              <img src={facebook} />
            </Link>
            <Link className="" to={""}>
              <img src={linkedin} />
            </Link>
            <Link className="" to={""}>
              <img src={x} />
            </Link>
          </div> */}

      {/* <div className="space-y-4">
            <h3 className="text-lg font-semibold">Contact Us</h3>
            <p className="flex items-center gap-2 font-medium">
              <img src={mapIcon} alt="map" />
              <span> Lorem Ipsum,Street-1,Gurugram</span>
            </p>
            <p className="flex items-center gap-2 font-medium">
              <img src={callIcon} alt="call" />
              <span> 000-889974532</span>
            </p>
            <p className="flex items-center gap-2 font-medium">
              <img src={mailIcon} alt="mail" />
              <span>digiwave@demo.com</span>
            </p>
          </div> */}
      {/* </div> */}
      {/* </div> */}
    </footer>
  );
};

export default Footer;
