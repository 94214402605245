import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";

import "./index_1.css";

import { AppProvider } from "./context/AppContext.tsx";
import { IFBuyerDataContextProvider } from "./context/IFBuyerDataContext.tsx";
import { PartnersDatContextProvider } from "./context/PartnersDataContext.tsx";
import { SimplePaymentDataContextProvider } from "./context/SimplePaymentDataContext.tsx";
import { CheckAvailabilityContextProvider } from "./context/CheckAvailabilityContext.tsx";
import { WalletFundContextProvider } from "./context/WalletFundContext.tsx";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <AppProvider>
      <CheckAvailabilityContextProvider>
        <PartnersDatContextProvider>
          <SimplePaymentDataContextProvider>
            <IFBuyerDataContextProvider>
              <WalletFundContextProvider>
                <App />
              </WalletFundContextProvider>
            </IFBuyerDataContextProvider>
          </SimplePaymentDataContextProvider>
        </PartnersDatContextProvider>
      </CheckAvailabilityContextProvider>
    </AppProvider>
  </StrictMode>
);
