import { z } from "zod";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ACCEPTED_FILE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
];

export const ApplicantDetailsSchema1 = z.object({
  first_name: z.string().trim().min(1, "Field is required"),
  last_name: z.string().trim().min(1, "Field is required"),
  user_nationality: z
    .string({ message: "Nationality is required" })
    .trim()
    .min(1, "Nationality is required"),
  user_dateOfBirth: z
    .string({ message: "DOB is required" })
    .trim()
    .min(1, "DOB is required"),

  job_title: z.string().trim().min(1, "Field is required"),
  work_email: z.string().trim().email("Email is not valid"),
  countryCode: z
    .string({ message: "Country code is required" })
    .trim()
    .min(1, "Country code is required"),

  // company_reg_country_code: z
  //   .string()
  //   .trim()
  //   .min(1, "Country code is required"),
  company_phone_number: z.string().trim().min(1, "Phone number is required"),

  user_addressLine1: z
    .string({ message: "Address is required" })
    .trim()
    .min(1, "Address is required")
    .min(5, "Address can't be less than 5 characters"),

  user_city: z
    .string({ message: "City is required" })
    .trim()
    .min(1, "City is required"),
  user_state: z
    .string({ message: "State is required" })
    .trim()
    .min(1, "State is required"),
  user_country: z
    .string({ message: "Country is required" })
    .trim()
    .min(1, "Country is required"),
  user_postcode: z
    .string({ message: "Post code is required" })
    .trim()
    .min(1, "Post code is required"),

  user_documentType: z
    .string({ message: "Document type is required" })
    .trim()
    .min(1, "Document type is required"),
  user_documentNumber: z
    .string({ message: "Document number is required" })
    .trim()
    .min(1, "Document number is required"),
  user_documentIssuanceCountry: z
    .string({ message: "Document issuance country is required" })
    .trim()
    .min(1, "Document issuance country is required"),
});

export const RegistrationFormSchemaStep2 = z.object({
  company_name: z
    .string({ message: "Company name is required" })
    .trim()
    .min(1, "Company name is required"),
  company_reg_number: z
    .string({ message: "Reg number is required" })
    .trim()
    .min(1, "Reg number is required")
    .min(6, "Reg number must be 6 characters")
    .max(6, "Reg number must be 6 characters"),
  trade_name: z
    .string({ message: "Trade name is required" })
    .trim()
    .min(1, "Trade name is required"),
  website: z
    .string({ message: "Website is required" })
    .trim()
    .min(1, "Website is required"),
  // company_phone_number: z
  //   .string({ message: "Phone number is required" })
  //   .trim()
  //   .min(1, "Phone number is required"),
  business_type: z
    .string({ message: "Business type is required" })
    .trim()
    .min(1, "Business type is required"),
  company_description: z
    .string({ message: "Company description is required" })
    .trim()
    .min(1, "Company description is required"),
  company_reg_date: z.string({ message: "Date is required" }).date(),
  company_reg_country: z
    .string({ message: "Register country is required" })
    .trim()
    .min(1, "Register country is required"),
  // company_reg_country_code: "US",
  company_listed_exchange: z
    .string({ message: "Listed exchange is required" })
    .trim()
    .min(1, "Listed exchange is required")
    .optional(),
  totalEmployees: z
    .string()
    .trim()
    .min(1, { message: "Select total employees" }),
  industrySector: z
    .string()
    .trim()
    .min(1, { message: "Select industry sector" }),
  intendedUseOfAccount: z
    .string()
    .trim()
    .min(1, { message: "Select intended use of account" }),
  // company_reg_address: z
  //   .string({ message: "Registered address is required" })
  //   .trim()
  //   .min(1, "Registered address is required"),
  annualTurnover: z
    .string({ message: "company turnover is required" })
    .trim()
    .min(1, "company turnover is required"),
  // business_address: z
  //   .string({ message: "business address is required" })
  //   .trim()
  //   .min(1, "business address is required"),

  registered_addressLine1: z
    .string({ message: "Address is required" })
    .trim()
    .min(1, "Address is required")
    .min(5, "Address can't be less than 5 characters"),

  registered_addressLine2: z.string().trim().optional().nullable(),

  registered_city: z
    .string({ message: "City is required" })
    .trim()
    .min(1, "City is required"),
  registered_state: z
    .string({ message: "State is required" })
    .trim()
    .min(1, "State is required"),
  registered_country: z
    .string({ message: "Country is required" })
    .trim()
    .min(1, "Country is required"),
  registered_postcode: z
    .string({ message: "Post code is required" })
    .trim()
    .min(1, "Post code is required"),

  business_documents_details: z.array(
    z.object({
      documents_type: z
        .string({ message: "Document type is required" })
        .trim()
        .min(1, "Document type is required"),
      documents_file_path: z
        .string()
        // .trim()
        .min(1, "Please select a document")
        .includes("https://", { message: "Please select a document" }),

      // .optional(),
      // file: z
      //   .instanceof(FileList, { message: "Please select a document" })
      //   .optional(), // Ensure the input is an instance of File
      // .refine((file) => file[0]?.size <= MAX_FILE_SIZE, {
      //   message: `File size must be less than ${Math.round(
      //     MAX_FILE_SIZE / 1000000
      //   )}MB.`,
      // })
      // .refine((file) => ACCEPTED_FILE_TYPES.includes(file[0]?.type), {
      //   message: "Only .png .jpeg/jpg .pdf and .docx formats are supported.",
      // }),
    })
  ),
});

export const RegistrationFormSchemaStep3 = z.object({
  founders: z.array(
    z.object({
      fid: z.number().optional(),
      founder_type: z.number().default(0),
      first_name: z
        .string({ message: "First name is required" })
        .trim()
        .min(1, "First name is required"),
      middle_name: z.string().nullable().optional(),
      last_name: z
        .string({ message: "Last name is required" })
        .trim()
        .min(1, "Last name is required"),
      nationality: z
        .string({ message: "Nationality is required" })
        .trim()
        .min(1, "Nationality is required"),
      date_of_birth: z.string({ message: "Date is required" }).date(),
      professional_position: z
        .string({ message: "Professional position is required" })
        .trim()
        .min(1, "Professional position is required"),
      // residential_address: z
      //   .string({ message: "Residential address is required" })
      //   .trim()
      //   .min(1, "Residential address is required"),
      email: z
        .string({ message: "Enter a valid email" })
        .trim()
        .email("Enter a valid email"),

      phone_number: z
        .string({ message: "Phone number is required" })
        .trim()
        .min(1, "Phone number is required"),

      addressLine1: z
        .string({ message: "Address is required" })
        .trim()
        .min(1, "Address is required")
        .min(5, "Address can't be less than 5 characters"),

      city: z
        .string({ message: "City is required" })
        .trim()
        .min(1, "City is required"),
      state: z
        .string({ message: "State is required" })
        .trim()
        .min(1, "State is required"),
      country: z
        .string({ message: "Country is required" })
        .trim()
        .min(1, "Country is required"),
      postcode: z
        .string({ message: "Post code is required" })
        .trim()
        .min(1, "Post code is required"),

      company_authorisation_documents_details: z.array(
        z.object({
          document_type: z
            .string({ message: "Document type is required" })
            .trim()
            .min(1, "Document type is required"),
          document_number: z
            .string({ message: "Document number is required" })
            .trim()
            .min(1, "Document number is required"),
          issuance_country: z
            .string({ message: "Issuance country is required" })
            .trim()
            .min(1, "Issuance country is required"),
          expiry_date: z.string({ message: "Date is required" }).date(),
          document_file_path: z
            .string()
            .min(1, "Document is required")
            .includes("https://", { message: "Please upload document" }),
          // .optional(),
          // file: z
          //   .instanceof(FileList, { message: "Please select a document" })
          //   .optional(), // Ensure the input is an instance of File
          // .refine((file) => file[0]?.size <= MAX_FILE_SIZE, {
          //   message: `File size must be less than ${Math.round(
          //     MAX_FILE_SIZE / 1000000
          //   )}MB.`,
          // })
          // .refine((file) => ACCEPTED_FILE_TYPES.includes(file[0]?.type), {
          //   message:
          //     "Only .png .jpeg/jpg .pdf and .docx formats are supported.",
          // }),
        })
      ),
    })
  ),
});

export const FullRegistrationFormSchema = RegistrationFormSchemaStep2.and(
  RegistrationFormSchemaStep3
).and(ApplicantDetailsSchema1);

export type FullRegistrationFormType = z.infer<
  typeof FullRegistrationFormSchema
> & {
  credit_limit?: string;
  two_fa?: number;
  totalEmployees?: string;
  status: string;
  company_region: string;
};
