// import { matchPath, Outlet, useLocation, useNavigate } from "react-router-dom";

// import { useAppContext } from "../context/AppContext";

import Chart1 from "../assets/img/chart1.png";
import Chart2 from "../assets/img/chart2.png";
import USAFlag from "../assets/img/USA-flag.png";
import AddIcon from "../components/Icons/AddIcon";
import CheckAvailability from "../components/CheckAvailability";
import SimplePaymentForm from "../components/SimplePayment/SimplePaymentForm";
import WalletCard from "../components/Dashboard/WalletCard";
import { useWalletFundContext } from "../context/WalletFundContext";
import ActionBanner from "../components/Dashboard/ActionBanner";

export const SimplePaymentLayout = () => {
  const { walletData: walletDataArr } = useWalletFundContext();

  const walletData = walletDataArr?.find((el) => el.curSymbol === "USD");

  // const { checkAvailability } = useAppContext();

  // const location = useLocation();

  // const routes = [
  //   {
  //     id: 1,
  //     route: "/simple-payment/sender-beneficiary",
  //     name: "Beneficiary",
  //   },
  //   { id: 2, route: "/simple-payment/details", name: "Details" },
  //   { id: 3, route: "/simple-payment/summary", name: "Summary" },
  // ];

  // const isActiveRoute = (route: string) => {
  //   return matchPath(location.pathname, route) !== null;
  // };

  return (
    <div>
      <ActionBanner />
      <div className="grid grid-cols-[2fr,2fr,1fr] gap-6 mb-6">
        <div className="card h-[139px] flex flex-col justify-between">
          <h2 className="font-semibold text-lg mt-2">Total Amount</h2>

          <div className="flex justify-between items-center">
            <div>
              <p>
                <span className="font-bold text-3xl">
                  {walletData?.balance}
                </span>{" "}
                <span className="font-medium text-xs text-[#9C9FAD]">
                  / This Month
                </span>
              </p>
              <small className="text-[#9C9FAD]">1050.00.last month</small>
            </div>

            <div>
              <img src={Chart1} alt="chart" />
            </div>
          </div>
        </div>

        {/* <div className="flip-card w-[412px] p-4 bg-white rounded-lg border border-[#f3f3f5]">
          <div className="flip-card-inner relative w-full h-full">
            <div className="flip-card-front">
              <div className="flex items-center justify-between mb-2">
                <h2 className="font-semibold text-lg">USD - Account</h2>
                <img src={USAFlag} alt="usa flag" />
              </div>

              <div className="flex justify-between items-center">
                <div>
                  <p>
                    <span className="font-bold text-3xl">557.00</span>{" "}
                    <span className="font-medium text-xs text-[#9C9FAD]">
                      / This Month
                    </span>
                  </p>
                  <small className="text-[#9C9FAD]">100.00.last month</small>
                </div>

                <div>
                  <img src={Chart2} alt="chart" />
                </div>
              </div>
            </div>

            <div className="flip-card-back">
              <h3 className="font-medium">Account Details:</h3>
              <div>
                <p className="text-[12px] flex justify-between">
                  <span>Account No.:</span>{" "}
                  <span className="text-[#6C6868]">01299319329201</span>
                </p>
                <p className="text-[12px] flex justify-between">
                  <span>IBAN Account:</span>{" "}
                  <span className="text-[#6C6868]">PADG01299319329201</span>
                </p>
                <p className="text-[12px] flex justify-between">
                  <span>Routing No:</span>{" "}
                  <span className="text-[#6C6868]">12997</span>
                </p>
                <p className="text-[12px] flex justify-between">
                  <span>Bank Address:</span>
                  <span className="text-[#6C6868]">
                    Lorem Ipsum is the dummy dummy
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <WalletCard />

        <div className="w-[255px] card flex flex-col justify-center gap-2">
          <button className="flex items-center gap-2 font-semibold text-[#6F6D6D]">
            <AddIcon className="size-9" />
            <span>Add New Currency</span>
          </button>
          <p className="text-sm text-[#9C9FAD]">
            Add a new currency to your account
          </p>
        </div>
      </div>

      <div className="bg-white rounded-md p-8 border border-[#f3f3f5]">
        <div className="mb-8 flex justify-between items-center">
          <h2 className="text-xl font-bold">Payment</h2>
          {/* {location.pathname === "/simple-payment/sender-beneficiary" && (
            <CheckAvailability  />
          )} */}

          <div
          // className={
          //   location.pathname === "/simple-payment/sender-beneficiary"
          //     ? "block"
          //     : "hidden"
          // }
          >
            <CheckAvailability />
          </div>
        </div>

        {/* <div className=" flex">
          <nav className="w-[171px] p-1 flex flex-col gap-[1px] border-r border-[#EAECF0]">
            {routes.map((el) => (
              <div key={el.route}>
                <div
                  className={`w-full py-2 transition-colors duration-700 ${
                    isActiveRoute(el.route)
                      ? "text-custom-blue-medium"
                      : "text-[#475467]"
                  }`}
                >
                  <p className="flex items-center gap-1">
                    <span
                      className={`w-8 h-8 text-xs font-semibold rounded-full inline-flex items-center justify-center border-2 ${
                        isActiveRoute(el.route)
                          ? "text-custom-blue-medium border-custom-blue-medium"
                          : "border-[#D0D5DD]"
                      }`}
                    >
                      0{el.id}
                    </span>{" "}
                    {el.name}
                  </p>
                </div>
                {el.id !== routes.length && (
                  <div className="w-[1px] h-12 bg-[#D0D5DD] ml-4" />
                )}
              </div>
            ))}
          </nav>

          <div className="relative w-full">
            {!checkAvailability && (
              <div className="absolute top-0 left-0 right-0 bottom-0 z-10 backdrop-blur-sm">
                <div className="h-full flex justify-center">
                  <p className="text-red-300 font-medium pt-5">
                    Please fill in the origin and destination details to proceed
                    to the next step.
                  </p>
                </div>
              </div>
            )}

            <Outlet />
          </div>
        </div> */}
        <SimplePaymentForm />
      </div>
    </div>
  );
};
