import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormValuesType } from "../../pages/LCargoDetails";
import { toast } from "react-toastify";
import SearchInput, { GeoNames } from "../SearchInput";
// import IconTextInput from "../IconTextInput";
import { today } from "../../utils";

import mapPin from "../../assets/icons/pin.png";
// import calenderIcon from "../../assets/icons/date.png";
import Dropdown from "../DropDown";
import InputWithLabel from "../InputWithLabel";
import {
  CONTAINER_TYPE,
  SHIPPING_TYPE,
  TRANSPORTATION_BY,
} from "../../constants/variable";
import { useForm } from "react-hook-form";
import Tooltip from "../Tooltip";
import {
  container_type,
  customs_clearance,
  frieght_basis,
  insurance,
  loading_date,
  loading_weight,
  shipping_category,
  shipping_type,
  transportation_by,
} from "../../constants/tooltipContents";
// import shipIcon from "../assets/icons/box.png";

type FromAndToLocation = {
  from: Omit<GeoNames, "adminCode1" | "geonameId">;
  to: Omit<GeoNames, "adminCode1" | "geonameId">;
};

type SimpleBookingFormType = FormValuesType & {
  weight: string;
  customs_clearance: boolean;
  insurance: boolean;
  invoice_amount: number;
};

const initial_location_state: FromAndToLocation = {
  from: { countryName: "", countryCode: "", lat: "", lng: "", name: "" },
  to: { countryName: "", countryCode: "", lat: "", lng: "", name: "" },
};

export default function SimpleBooking() {
  const navigate = useNavigate();

  //   const [toggle, setToggle] = useState(false);
  // const [isChecked, setIsChecked] = useState(false);
  //   const [shippingTypePopUp, setShippingTypePopUp] = useState(false);

  const [locationVales, setLocationVales] = useState(initial_location_state);

  //   const onToggle = () => setShippingTypePopUp(!shippingTypePopUp);

  const methods = useForm<SimpleBookingFormType>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = methods;

  const selectedInsuranceService = watch("insurance");

  const onSubmitHandler = async (values: SimpleBookingFormType) => {
    console.log(values, locationVales);

    if (
      locationVales.from.name.trim() === "" ||
      locationVales.to.name.trim() === ""
    ) {
      toast.error("Please Select a source and destination", {
        position: "top-center",
      });

      return;
    }

    localStorage.setItem(
      "cargo-details",
      JSON.stringify({ ...locationVales, ...values })
    );

    const sendData = {
      container_size: values.shipppingType?.container_size,
      freight_type: values.shipppingType?.freight_type,
      from_city: locationVales.from.countryName,
      from_countrycode: locationVales.from.countryCode,
      from_lat: locationVales.from.lat,
      from_lng: locationVales.from.lng,
      height: 0,
      length: 0,
      shipping_date: values.date,
      to_city: locationVales.to.countryName,
      to_countrycode: locationVales.to.countryName,
      to_lat: locationVales.to.lat,
      to_lng: locationVales.to.lng,
      weight: values.weight,
      width: 0,
    };

    navigate("/logistics/quotes", { state: { data: sendData } });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className="space-y-2">
      <div className="flex gap-5">
        <Dropdown
          element={<Tooltip content={shipping_type} />}
          label="Shipping Type"
          placeholder="Select type"
          options={SHIPPING_TYPE.map((el) => ({ value: el, label: el }))}
          {...register("shipppingType.freight_type", {
            required: "Select a type",
          })}
          error={errors.shipppingType?.freight_type?.message}
        />

        <InputWithLabel
          // element={<Tooltip content={shipping_category} />}
          label="Category"
          type="text"
          element={
            <div className="flex items-center space-x-2">
              <small className="text-custom-blue-new">HS CODES</small>
              <Tooltip content={shipping_type} />
            </div>
          }
          placeholder="Enter category"
        />
      </div>

      <div className="flex gap-5">
        <Dropdown
          element={<Tooltip content={transportation_by} />}
          label="Transportation By"
          placeholder="Select type"
          options={TRANSPORTATION_BY}
          {...register("shipppingType.transportation_by", {
            required: "Select a type",
          })}
          error={errors.shipppingType?.transportation_by?.message}
        />

        <Dropdown
          label="Container Type"
          element={<Tooltip content={container_type} />}
          placeholder="Select type"
          options={CONTAINER_TYPE}
          {...register("shipppingType.container_size", {
            required: "Select a type",
          })}
          error={errors.shipppingType?.container_size?.message}
        />
      </div>

      <div className="flex gap-5">
        <div className="w-full mt-1">
          <InputWithLabel
            label="Container Quantity"
            placeholder="Enter quantity"
          />
        </div>

        <Dropdown
          element={<Tooltip content={frieght_basis} />}
          label="Fright Basis"
          placeholder="Select type"
          options={CONTAINER_TYPE}
        />
      </div>

      <div className="flex gap-5">
        <div className="w-full">
          <label className="text-[#344054] text-sm font-medium" htmlFor="from">
            From
          </label>
          <SearchInput
            placeholder="Source"
            name="from"
            initialCity=""
            setFormValues={setLocationVales}
            icon={mapPin}
            className="input-new !pl-[40px]"
          />
          <small className="text-red-500 h-[10px] block ml-1">
            {errors.from && errors.from.message}
          </small>
        </div>
        <div className="w-full">
          <label className="text-[#344054] text-sm font-medium" htmlFor="to">
            To
          </label>
          <SearchInput
            placeholder="Destination"
            name="to"
            initialCity=""
            setFormValues={setLocationVales}
            icon={mapPin}
            className="input-new !pl-[40px]"
          />
          <small className="text-red-500 h-[10px] block ml-1">
            {errors.to && errors.to.message}
          </small>
        </div>
      </div>

      <div className="flex gap-5 items-center">
        <div className="w-full mt-1">
          <InputWithLabel
            element={<Tooltip content={loading_date} />}
            label="Loading Date"
            type="date"
            min={today}
            {...register("date", { required: "Select a date" })}
            error={errors.date?.message}
          />
        </div>

        <div className="w-full h-[85px] flex items-end">
          <InputWithLabel
            // element={<Tooltip content={loading_weight} />}
            label="Weight"
            type="text"
            placeholder="Enter weight"
            {...register("weight", { required: "Please enter some weight" })}
            error={errors.weight?.message}
          />
        </div>

        {/* <div className="w-[50%]">
          <label className="text-sm" htmlFor="date">
            Date
          </label>
          <IconTextInput
            type="date"
            placeholder="09-Sep 2024"
            name="date"
            icon={calenderIcon}
            id="no-datepicker"
            onFocus={(e) => {
              e.currentTarget.showPicker();
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormValues((prev) => ({
                ...prev,
                date: e.target?.value,
              }));
            }}
            // value={dateValue}
            min={today}
            className="input-new text-end"
          />
        </div> */}

        {/* <div className="relative w-[50%]">
                  <label htmlFor="Shipping Type" className="text-sm">
                    Shipping Type
                  </label>
                  <div
                    className="text-input flex items-center gap-1 !border-[#D0D5DD] mb-5"
                    onClick={onToggle}
                  >
                    <img src={shipIcon} alt="shipIcon" />
                    <p
                      className={`${
                        formVales.shipppingType.freight_type
                          ? "text-black"
                          : "text-[#aaa]"
                      }`}
                    >
                      {formVales.shipppingType.freight_type
                        ? `${formVales.shipppingType.freight_type}, ${formVales.shipppingType.container_size}, ${formVales.shipppingType?.transportation_by}`
                        : "Shipping Type"}
                    </p>
                  </div>

                  {shippingTypePopUp && (
                    <ShippingDropDown
                      setFormValues={setFormValues}
                      onToggle={onToggle}
                    />
                  )}
                </div> */}
      </div>

      <div className="text-[#344054]">
        <div className="flex justify-between items-center">
          <h3 className="mb-3">Additional Service:</h3>
        </div>

        <div className="flex gap-5">
          <div className="w-full space-y-2">
            <div className="flex justify-end">
              <Tooltip content={customs_clearance} />
            </div>

            <label className="w-full border p-2 rounded-lg flex items-center gap-2 cursor-pointer">
              <input
                type="checkbox"
                className="size-4"
                {...register("customs_clearance")}
              />
              <svg
                className="size-4"
                viewBox="0 0 11 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.5001 0.5L5.0001 1.5H10.0001L10.5001 0.5H4.5001ZM5.0001 2V3.25C5.0001 3.25 5.0001 6 7.5001 6C10.0001 6 10.0001 3.25 10.0001 3.25V2H5.0001ZM0.9841 3.486C0.719224 3.49021 0.466843 3.59935 0.282357 3.78946C0.0978713 3.97957 -0.0036423 4.23512 9.99123e-05 4.5V8.5C-2.42119e-05 8.64982 0.0335183 8.79776 0.0982491 8.93288C0.16298 9.06799 0.257244 9.18684 0.374077 9.28063C0.49091 9.37443 0.627324 9.44077 0.773241 9.47476C0.919158 9.50875 1.07085 9.50951 1.2171 9.477L4.0001 8.857V13.5L9.8791 6.57C9.73706 6.52644 9.58964 6.50288 9.4411 6.5H5.5001L2.0001 7.254V4.5C2.00199 4.36615 1.97699 4.23328 1.92657 4.10927C1.87616 3.98526 1.80136 3.87263 1.70661 3.77807C1.61186 3.6835 1.49909 3.60893 1.37498 3.55876C1.25087 3.50859 1.11795 3.48384 0.9841 3.486ZM10.7481 7.211L5.4121 13.5H11.0001V8.059C11.0001 7.745 10.9061 7.455 10.7481 7.211Z"
                  fill="black"
                />
              </svg>
              Customs Clearance
            </label>
          </div>

          <div className="w-full space-y-2 pb-4">
            <div className="flex justify-end">
              <Tooltip content={insurance} />
            </div>
            <label className="w-full border p-2 rounded-lg flex items-center gap-2 cursor-pointer">
              <input
                type="checkbox"
                className="size-4"
                {...register("insurance")}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-4 text-black"
              >
                <path
                  fillRule="evenodd"
                  d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                  clipRule="evenodd"
                />
              </svg>
              Insurance
            </label>
            {selectedInsuranceService && (
              <InputWithLabel
                label="Invoice Amount"
                placeholder="Enter value"
                type="number"
                {...register("invoice_amount", {
                  required: "Enter a invoice amount",
                })}
                error={errors.invoice_amount?.message}
              />
            )}
          </div>
        </div>
      </div>

      <hr />

      <div className="pt-5 float-end">
        <button
          type="submit"
          // disabled={isSubmitting}
          className="w-[146px] h-[44px] rounded-md bg-custom-blue-medium text-white font-medium"
        >
          Continue
        </button>
      </div>
    </form>
  );
}
